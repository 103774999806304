<template>

  <v-container
      id="createEnvironment"
      fluid
      tag="section"
  >
    <base-material-card
        color="success"
        icon="mdi-earth"
        title="Creating a new Environment"
        inline
    >
      <v-row no-gutters class="mt-10">
        <v-col cols="12" class="pl-5">

          <v-stepper v-model="step">

            <v-stepper-header>
              <v-stepper-step
                  @click="step=1"
                  :editable="stepper.step1"
                  :complete="step >1"
                  step="1"
              >
                <div :class="{'secondary--text': step === 1 , 'grey--text': step !== 1}">
                  <span>General Information</span>
                </div>
              </v-stepper-step>

              <v-stepper-step
                  @click="(stepper.step1)?step=2:'' "
                  :editable="stepper.step1 && stepper.step2"
                  :complete="step > 2"
                  step="2"
              >
                <div :class="{'secondary--text': step === 2 , 'grey--text': step !== 2}">
                  <span>Security</span>
                </div>
              </v-stepper-step>

              <v-stepper-step
                  @click="(stepper.step1 && stepper.step2)?step=3:''"
                  step="3"
                  :editable="stepper.step1 && stepper.step3 && stepper.step2"
                  :complete="step > 3"
              >
                <div :class="{'secondary--text': step === 3 , 'grey--text': step !== 3}">
                  <span>Settings</span>
                </div>
              </v-stepper-step>

              <v-stepper-step
                  @click="(stepper.step3 && stepper.step1 && stepper.step2)?step=4:''"
                  step="4"
                  :editable="stepper.step4 && stepper.step3"
                  :complete="step > 4"
              >
                <div :class="{'secondary--text': step === 4 , 'grey--text': step !== 4}">
                  <span>Applications & Resources</span>
                </div>
              </v-stepper-step>

              <v-stepper-step
                  @click="(stepper.step3 && stepper.step1 && stepper.step2 && stepper.step4)?step=5:''"
                  step="5"
              >
                <div :class="{'secondary--text': step === 5 , 'grey--text': step !== 5}">
                  <span>Review & Finalize</span>
                </div>
              </v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <!-- General Information -->
              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="9">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            label="Code *"
                            hint="An environment code is Unique. It is the main reference based on which all configuration and settings are loaded."
                            persistent-hint
                            v-model="environment.code"
                            :rules="[() => !!environment.code || 'This field is required!']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            counter
                            label="Description"
                            v-model="environment.description"
                            rows="3"
                            hint="Optional description only used for management purposes"
                            persistent-hint
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                            label="Caching TTL *"
                            v-model="environment._TTL"
                            :rules="[() => !!environment._TTL || 'This field is required!']"
                            required
                            item-text="text"
                            item-value="value"
                            :items="msCacheOptions"
                            hint="The gateway caches the environment configuration at runtime and that enables it to operate at optimal speed."
                            persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" xs="6" md="4">
                        <v-text-field
                            label="Stats URI *"
                            hint="The gateway offers analytical metrics out of the box. Define the endpoint that permits accessing these metrics."
                            persistent-hint
                            v-model="environment.stats.uri"
                            :rules="[() => !!environment.stats.uri || 'This field is required!']"
                        />
                      </v-col>
                      <v-col cols="4" xs="6" md="4">
                        <v-switch
                            inset
                            :label="(environment.stats.secure) ? 'Restricted to authorized personnel only' : 'Publicly available'"
                            hint="Specify whether the exposed gateway endpoint to access the analytical metrics requires authorization or if it is public. "
                            persistent-hint
                            v-model="environment.stats.secure"
                        />
                      </v-col>
                      <v-col cols="4" xs="6" md="4">
                        <v-text-field
                            label="Stats Prefix"
                            hint="The exposed metrics can be pushed to an Elastic Search database. Provide an optional prefix to use with Elastic Search."
                            persistent-hint
                            v-model="environment.stats.prefix"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="text-center align-content-center">
                    <v-color-picker
                        class="my-0 mx-auto"
                        dot-size="25"
                        mode="hexa"
                        show-swatches
                        swatches-max-height="120"
                        v-model="environment.color"
                    ></v-color-picker>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <!-- Security -->
              <v-stepper-content step="2">
                <v-expansion-panels readonly focusable multiple :value="[0,1,2,3,4]">
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Applications Security</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              label="Tenant Key Encryption Phrase *"
                              hint="Set the Multitenancy encryption phrase of the M360API Gateway."
                              persistent-hint
                              v-model="environment.security.key.password"
                              :rules="[() => !!environment.security.key.password || 'This field is required!']"
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Oauth Security</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6" xs="12" md="6">
                          <v-select
                              label="Access Token Lifetime *"
                              v-model="environment.security.oauth.accessTokenLifetime"
                              :rules="[() => !!environment.security.oauth.accessTokenLifetime || 'This field is required!']"
                              required
                              item-text="text"
                              item-value="value"
                              :items="secondsCacheOptions"
                              hint="Set the lifetime of the Oauth access token that the gateway uses."
                              persistent-hint
                          ></v-select>
                        </v-col>
                        <v-col cols="6" xs="12" md="6">
                          <v-select
                              label="Refresh Token Lifetime *"
                              v-model="environment.security.oauth.refreshTokenLifetime"
                              :rules="[() => !!environment.security.oauth.refreshTokenLifetime || 'This field is required!']"
                              required
                              item-text="text"
                              item-value="value"
                              :items="secondsCacheOptions"
                              hint="Set the lifetime of the Oauth refresh token that the gateway uses."
                              persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              label="Oauth Encryption Phrase *"
                              hint="Set the encryption phrase of the Oauth security."
                              persistent-hint
                              v-model="environment.security.oauth.secret"
                              :rules="[() => !!environment.security.oauth.secret || 'This field is required!']"
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>CORS Configuration</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6" xs="12" md="6">
                          <v-switch
                              label="Enable Cors"
                              inset
                              persistent-hint
                              hint="Configure the CORS security of the gateway."
                              v-model="environment.security.cors.enabled"></v-switch>
                        </v-col>
                        <v-col cols="6" xs="12" md="6">
                          <v-switch
                              label="Allow Credentials"
                              inset
                              persistent-hint
                              hint="Set whether to allow or deny credentials support in CORS."
                              v-model="environment.security.cors.credentials"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              label="Allow Origin"
                              v-model="environment.security.cors.origin"
                              persistent-hint
                              hint=""
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="ml-1">
                          <v-select
                              v-model="environment.security.cors.methods"
                              :items="['GET','HEAD','PUT','PATCH','POST','DELETE']"
                              label="Methods"
                              multiple
                              chips
                              clearable
                              deletable-chips
                              hint="Select which Methods are allowed in the CORS Configuration."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item }}</span>
                              </v-chip>
                            </template>

                            <template v-slot:item="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              <strong class="mr-3">{{ data.item }}</strong>
                            </template>

                            <template v-slot:append-item>
                              <v-divider class="mb-2"></v-divider>
                              <v-list-item disabled>
                                <v-list-item-content>
                                  <v-list-item-title>CORS Configuration can include multiple methods.
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12" class="ml-1">
                          <v-text-field
                              label="Allowed Headers"
                              hint="Add here all the possible key values that are needed in your headers"
                              persistent-hint
                              v-model="environment.security.cors.headers"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="ml-1">
                          <v-select
                              label="Max Age *"
                              v-model="environment.security.cors.maxAge"
                              :rules="[() => !!environment.security.cors.maxAge || 'This field is required!']"
                              required
                              item-text="text"
                              item-value="value"
                              :items="secondsCacheOptions"
                              hint="Set the Max Age of your CORS configuration."
                              persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>ACL Permissions</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="ACL Permissions"
                              v-model="environment.roles"
                              item-text="label"
                              item-value="id"
                              :items="roles"
                              hint="Attach ACL Permissions created in other environments to this new environment."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-stepper-content>

              <!-- Settings -->
              <v-stepper-content step="3">
                <v-expansion-panels focusable readonly multiple :value="[0,1]">
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Logger</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-jsoneditor
                              class="py-2"
                              v-model="environment.logger" :plus="true" :options="{mode: 'code'}" height="300px"
                          />
                          <div class="mt-5 text-muted">The gateway and the middleware use
                            <a href="https://www.npmjs.com/package/bunyan" target="_blank">Bunyan</a> as their logging
                            library
                            along with the above logger configuration.
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Custom Settings</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="Custom Settings"
                              v-model="environment.custom"
                              item-text="label"
                              item-value="id"
                              :items="customRegistries"
                              hint="Attach custom registries created in other environments to this new environment."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-stepper-content>

              <!-- Resources -->
              <v-stepper-content step="4">
                <v-expansion-panels focusable readonly multiple :value="[0,1,2]">
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Clusters</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="Resources"
                              v-model="environment.resources"
                              item-text="label"
                              item-value="id"
                              :items="resources"
                              hint="Attach resources created in other environments to this new environment."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Databases</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="Databases"
                              v-model="environment.databases"
                              item-text="label"
                              item-value="name"
                              :items="databases"
                              hint="Copy Databases created in other environments to this new environment."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Applications</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                              multiple
                              chips
                              deletable-chips
                              label="Applications"
                              v-model="environment.tenants"
                              item-text="label"
                              item-value="id"
                              :items="tenants"
                              hint="Attach Applications created in other environments to this new environment."
                              persistent-hint
                          >
                            <template v-slot:selection="data">
                              <!-- HTML that describe how select should render selected items -->
                              <v-chip close color="primary">
                                <span>{{ data.item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-stepper-content>

              <!-- REview -->
              <v-stepper-content step="5">
                <v-expansion-panels focusable multiple :value="[0, 1, 2, 3, 4, 5, 6]">
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>General Information</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <tbody>
                        <tr>
                          <td width="50%" class="v-data-table-header">CODE</td>
                          <td>{{ environment.code }}</td>
                        </tr>
                        <tr>
                          <td class="v-data-table-header">Description</td>
                          <td>{{ environment.description || 'N/A' }}</td>
                        </tr>
                        <tr>
                          <td class="v-data-table-header">Caching Limit</td>
                          <td>{{ getCacheHumanFromMS(environment._TTL) }}</td>
                        </tr>
                        <tr>
                          <td class="v-data-table-header">Metrics & Analytics</td>
                          <td>
                            <v-simple-table>
                        <tbody>
                        <tr>
                          <td width="50%">URI</td>
                          <td>{{ environment.stats.uri || 'N/A' }}</td>
                        </tr>
                        <tr>
                          <td width="50%">Public</td>
                          <td>{{ environment.stats.secure ? 'NO' : 'YES' }}</td>
                        </tr>
                        <tr>
                          <td width="50%">Prefix</td>
                          <td>{{ environment.stats.prefix || 'N/A' }}</td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                      </td>
                      </tr>
                      </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header hide-actions>Security</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <tbody>
                        <tr>
                          <td width="50%" class="v-data-table-header">Application Secret</td>
                          <td>{{ environment.security.key.password }}</td>
                        </tr>
                        <tr>
                          <td class="v-data-table-header">OAuth Secret</td>
                          <td>{{ environment.security.oauth.secret || 'N/A' }}</td>
                        </tr>
                        <tr>
                          <td class="v-data-table-header">CORS</td>
                          <td>{{ environment.security.cors.enabled ? 'Turned On' : 'Turned Off' }}</td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="environment.roles.length > 0">
                    <v-expansion-panel-header hide-actions>ACL Permissions</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-3">
                        <v-col cols="3"
                               class="pa-0"
                               v-for="(item, i) in environment.roles"
                               :key="i"
                        >
                          <div class="error lighten-2 coloredBox">
                            <v-icon class="mr-2">mdi-lock</v-icon>
                            {{ getTextFromId(item, 'role') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="environment.custom.length > 0">
                    <v-expansion-panel-header hide-actions>Settings</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-3">
                        <v-col cols="3"
                               class="pa-0"
                               v-for="(item, i) in environment.custom"
                               :key="i"
                        >
                          <div class="warning lighten-2 coloredBox">
                            <v-icon class="mr-2">mdi-cogs</v-icon>
                            {{ getTextFromId(item, 'custom') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="environment.tenants.length > 0">
                    <v-expansion-panel-header hide-actions>Applications</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-3">
                        <v-col cols="3"
                               v-for="(item, i) in environment.tenants"
                               :key="i"
                               class="pa-0"
                        >
                          <div class="purple lighten-2 coloredBox">
                            <v-icon class="mr-2">mdi-apps</v-icon>
                            {{ getTextFromId(item, 'tenant') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="environment.resources.length > 0">
                    <v-expansion-panel-header hide-actions>Resources</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-3">
                        <v-col cols="3"
                               class="pa-0"
                               v-for="(item, i) in environment.resources"
                               :key="i"
                        >
                          <div class="secondary lighten-2 coloredBox">
                            <v-icon class="mr-2">mdi-server</v-icon>
                            {{ getTextFromId(item, 'resource') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="environment.databases.length > 0">
                    <v-expansion-panel-header hide-actions>Databases</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-3">
                        <v-col cols="3"
                               class="pa-0"
                               v-for="(item, i) in environment.databases"
                               :key="i"
                        >
                          <div class="success lighten-2 coloredBox">
                            <v-icon class="mr-2">mdi-database</v-icon>
                            {{ getTextFromId(item, 'database') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-stepper-content>

            </v-stepper-items>

          </v-stepper>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="text-right pr-0 mr-0">
          <v-btn
              class="mr-2"
              color="error"
              @click="step--"
              v-if="step !== 1"
          >
            <v-icon class="mr-1">mdi-chevron-left</v-icon>
            Back
          </v-btn>

          <v-btn
              color="secondary"
              @click="step++"
              v-if="step >= 1 && step < 5"
              :disabled="!stepper[`step${step}`]"
              class="mr-3"
          >
            <v-icon class="mr-1">mdi-chevron-right</v-icon>
            Next
          </v-btn>

          <v-btn
              color="success"
              @click="doCreateEnvironment"
              v-if="canAccess({method: 'put', route: '/environments'}) && step === 5"
              class="mr-3"
          >
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Create
          </v-btn>

        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import globalMixins from "@/mixins/globalMixins";
import {uuid} from 'vue-uuid';
import VJsoneditor from 'v-jsoneditor'

export default {
  name: 'environmentWizard',

  mixins: [globalMixins],

  components: {
    VJsoneditor
  },

  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  },

  data() {
    return {
      step: 1,
      stepper: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false
      },
      environment: {
        color: '',
        code: '',
        description: '',
        logger: {
          "src": true,
          "level": "info",
          "format": {
            "level": "info",
            "levelInString": false,
            "outputMode": "long"
          },
          "streams": [
            {
              "level": "info",
              "stream": "process.stdout"
            },
            {
              "type": "rotating-file",
              "level": "info",
              "path": "/opt/projects/corsair/m360/logs/gateway.log",
              "period": "1d",
              "count": 5
            }
          ],
          "serializers": {
            "req": "function(req){ return { method: req.method, url: req.url, headers: req.headers, query: req.query, body: req.body, params: req.params }; }",
            "res": ""
          }
        },
        // caching TTL value in milliseconds
        // 7 days
        _TTL: 7 * 24 * 3600 * 1000,
        stats: {
          uri: '/stats',
          prefix: '',
          secure: false
        },
        "security": {
          "cors": {
            "enabled": true,
            "origin": "*",
            "credentials": true,
            "methods": ['GET', 'HEAD', 'PUT', 'PATCH', 'POST', 'DELETE'],
            "headers": "Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization,x-key",
            // max age in seconds
            "maxAge": 30 * 24 * 3600
          },
          "oauth": {
            "grants": [
              "password",
              "refresh_token"
            ],
            "secret": uuid.v4(),
            "hashAlgorithm": "sha1",
            "hashSize": 256,
            "digestEncoding": "hex",
            "debug": false,
            "accessTokenLifetime": 2 * 3600, // 2 hrs
            "refreshTokenLifetime": 7 * 24 * 3600 // 1 week
          },
          "key": {
            "algorithm": "aes256",
            "password": uuid.v4()
          }
        },
        custom: [],
        roles: [],
        resources: [],
        tenants: [],
        databases: []
      },
      customRegistries: [],
      roles: [],
      resources: [],
      tenants: [],
      databases: []
    }
  },

  watch: {
    "environment": {
      handler: function (value) {
        this.stepper.step1 = false;
        this.stepper.step2 = false;
        this.stepper.step3 = false;
        this.stepper.step4 = false;

        //step1 enabled
        if (
            value.code &&
            value.code.trim() !== ''
        ) {
          this.stepper.step1 = true;
        }

        //step 2 enabled
        if (
            value.security.key.password &&
            value.security.key.password.trim() !== '' &&

            value.security.oauth.secret &&
            value.security.oauth.secret.trim() !== '' &&

            value.security.oauth.accessTokenLifetime &&
            value.security.oauth.accessTokenLifetime > 0 &&

            value.security.oauth.refreshTokenLifetime &&
            value.security.oauth.refreshTokenLifetime > 0 &&

            value.security.cors.enabled &&
            value.security.cors.origin &&
            value.security.cors.methods.length > 0
        ) {
          this.stepper.step2 = true;
        }

        //step 3 enabled
        if (
            value.logger &&
            (JSON.stringify(value.logger) !== '{}' || JSON.stringify(value.logger) !== '')
        ) {
          this.stepper.step3 = true;
        }

        this.stepper.step4 = true;
      },
      deep: true
    },

    "step": async function (value) {
      if (value === 2) {
        await this.getRolesFromOtherEnvironments();
      } else if (value === 3) {
        await this.getCustomFromOtherEnvironments();
      } else if (value === 4) {
        await this.getResourcesFromOtherEnvironments();
        await this.getTenantsFromOtherEnvironments();
        await this.getDatabasesFromOtherEnvironments();
      }
    }
  },

  created() {
    this.randomColor();
  },

  methods: {

    randomColor() {
      this.environment.color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
    },

    async getCustomFromOtherEnvironments() {
      if (this.environments && this.environments.length > 0) {
        const apiOptions = {
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/default/custom-entries`,
          method: "get",
          params: {
            noEnv: true
          }
        };

        this.getSendData(apiOptions).then((response) => {

          response.items.forEach((oneCR) => {
            oneCR.label = `${oneCR.name} ( Default ) [ ${oneCR.locked ? 'private' : 'public'} ]`;
          });

          this.customRegistries = response.items;

          const apiOptions2 = {
            noLoading: true,
            url: `/consoleapi/environments/${this.envSelected.value}/arw/custom-entries`,
            method: "get",
            params: {
              noEnv: true
            }
          };

          this.getSendData(apiOptions2).then((response) => {

            response.items.forEach((oneCR) => {
              oneCR.label = `${oneCR.name} ( ARW ) [ ${oneCR.locked ? 'private' : 'public'} ]`;
            });

            this.customRegistries = this.customRegistries.concat(response.items);
          });

        });
      }
    },

    async getResourcesFromOtherEnvironments() {
      if (this.environments && this.environments.length > 0) {
        const apiOptions = {
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/data-clusters`,
          method: "get",
          params: {
            noEnv: true
          }
        };

        this.getSendData(apiOptions).then((response) => {
          this.resources = response.items;
          this.resources.forEach((oneResource) => {
            oneResource.label = `${oneResource.name} < ${oneResource.category} >`;
          });
        });
      }
    },

    async getTenantsFromOtherEnvironments() {
      if (this.environments && this.environments.length > 0) {

        const apiOptions = {
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/general/tenants`,
          method: "get",
          params: {
            noEnv: true
          }
        };

        this.getSendData(apiOptions).then((response) => {
          this.tenants = response.items;
          this.tenants.forEach((oneTenant) => {
            oneTenant.label = `${oneTenant.name} < ${oneTenant.description} >`;
          });

          const apiOptions2 = {
            noLoading: true,
            url: `/consoleapi/environments/${this.envSelected.value}/social/tenants`,
            method: "get",
            params: {
              noEnv: true
            }
          };

          this.getSendData(apiOptions2).then((response) => {
            response.items.forEach((oneTenant) => {
              oneTenant.label = `${oneTenant.name} < ${oneTenant.description} >`;
            });
            this.tenants = this.tenants.concat(response.items);
          });
        });
      }
    },

    async getDatabasesFromOtherEnvironments() {
      if (this.environments && this.environments.length > 0) {

        const apiOptions = {
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/databases`,
          method: "get",
          params: {
            noEnv: true
          }
        };

        this.getSendData(apiOptions).then((response) => {
          this.databases = response.items;
          this.databases.forEach((oneDatabase) => {
            oneDatabase.label = `${oneDatabase.name} < ${oneDatabase.multitenant ? 'Multitenant' : 'Shared'} >`;
          });
        });
      }

    },

    async getRolesFromOtherEnvironments() {

      if (this.environments && this.environments.length > 0) {

        const apiOptions = {
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/roles`,
          method: "get",
          params: {
            noEnv: true
          }
        };

        this.getSendData(apiOptions).then((response) => {
          this.roles = response.items;
          this.roles.forEach((oneRole) => {
            oneRole.label = `${oneRole.name} < ${oneRole.description} >`;
          });
        });
      }

    },

    getTextFromId(id, type) {
      let label = '';
      switch (type) {
        case 'role':
          this.roles.forEach((onerole) => {
            if (onerole.id === id) {
              label = onerole.name
            }
          });
          break;
        case 'custom':
          this.customRegistries.forEach((oneCustomEntry) => {
            if (oneCustomEntry.id === id) {
              label = oneCustomEntry.name
            }
          });
          break;
        case 'resource':
          this.resources.forEach((oneResource) => {
            if (oneResource.id === id) {
              label = oneResource.name
            }
          });
          break;
        case 'tenant':
          this.tenants.forEach((oneTenant) => {
            if (oneTenant.id === id) {
              label = oneTenant.name
            }
          });
          break;
        case 'database':
          label = id;
          break;
      }

      return label;
    },

    doCreateEnvironment() {
      if (confirm(`You are about to create a new environment named: ${this.environment.code}. Are you sure you want to proceed ?`)) {
        // cut off transparency
        // if color is 8 digits
        if (this.environment.color.length > 7) {
          this.environment.color = this.environment.color.substring(0, 7);
        }
        // this.environment.security.cors.methods = this.environment.security.cors.methods.join( "," );
        const apiOptions = {
          url: `/consoleapi/environments`,
          method: "put",
          params: {
            environment: this.environment
          }
        };

        if (this.environments && this.environments.length > 0) {
          apiOptions.params.fromEnv = this.envSelected.value;
        } else {
          delete apiOptions.params.environment.roles;
          delete apiOptions.params.environment.tenants;
          delete apiOptions.params.environment.resources;
          delete apiOptions.params.environment.databases;
          delete apiOptions.params.environment.custom;
        }

        this.getSendData(apiOptions)
            .then((response) => {
              this.pushMessage({
                type: 'success',
                title: 'Enviroment Created',
                text: `A new Environment ${this.environment.code} has been created!`
              });
              this.scrollToTop();
              this.$router.push({'name': 'Dashboard'});
              setTimeout(() => {
                window.location.reload();
              }, 400);
            });
      }
    }
  }
};
</script>
<style>
.coloredBox {
  border: solid 1px white;
  padding: 12px;
  width: 99%;
  margin: 1px 0;
}
</style>